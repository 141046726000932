<template>
    <div class="container-fluid text-white ps-5 pe-5">

      <div class="row">
        <div class="col-3 m-auto align-middle text-end">
          <h2 class="m-auto p-0 text-vertical d-inline-block font-size-3 fw-bold">
            {{ currentLanguage.skills.skillsMap }}
          </h2>
        </div>
        <div class="col-9 text-start">
          <div class="d-inline">

            <div class="d-inline-block text-start align-top col-10 ps-5">
              <ul class="list-unstyled">
                <li class="row pt-2">
                  <span class="col-3">
                    Javascript
                  </span>
                  <ProgressIndicator :percent="95"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Typescript
                  </span>
                  <ProgressIndicator :percent="85"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Vue
                  </span>
                  <ProgressIndicator :percent="95"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Angular
                  </span>
                  <ProgressIndicator :percent="20"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    React
                  </span>
                  <ProgressIndicator :percent="35"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Node
                  </span>
                  <ProgressIndicator :percent="95"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Python
                  </span>
                  <ProgressIndicator :percent="50"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    SQL
                  </span>
                  <ProgressIndicator :percent="90"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    MongoDB
                  </span>
                  <ProgressIndicator :percent="80"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    ElasticSearch
                  </span>
                  <ProgressIndicator :percent="75"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Ionic
                  </span>
                  <ProgressIndicator :percent="70"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Flutter
                  </span>
                  <ProgressIndicator :percent="72"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    UI/UX
                  </span>
                  <ProgressIndicator :percent="60"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    GCloud
                  </span>
                  <ProgressIndicator :percent="85"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    AWS
                  </span>
                  <ProgressIndicator :percent="50"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Azure
                  </span>
                  <ProgressIndicator :percent="10"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    Docker
                  </span>
                  <ProgressIndicator :percent="87"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    CI / CD
                  </span>
                  <ProgressIndicator :percent="78"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    SCRUM
                  </span>
                  <ProgressIndicator :percent="90"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    {{ currentLanguage.skills.portugueseItem }}
                  </span>
                  <ProgressIndicator :percent="99"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    {{ currentLanguage.skills.englishItem }}
                  </span>
                  <ProgressIndicator :percent="75"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
                <li class="row pt-2">
                  <span class="col-3">
                    LGPD / DPO
                  </span>
                  <ProgressIndicator :percent="90"
                                     direction="left"
                                     class="col-9 p-0 mt-1"></ProgressIndicator>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>

import $ from 'jquery'
import ProgressIndicator from '@/components/ProgressIndicator.vue'
// import { Modal } from 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  name: 'Skills',
  data: function () {
    return {

      currentLanguage: this.$store.getters.currentLanguage

    }
  },

  mounted () {
    $('title').html('David Bertoncello')
  },

  components: {
    ProgressIndicator
  },

  methods: {

  }
}

</script>

<style scoped>

.container-fluid{
    padding-top: 200px;
    padding-bottom: 200px;
}

.light-theme h2{
  color: #333;
}

.dark-theme h2{
    vertical-align: middle;
    /* -ms-writing-mode: tb-lr; */
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(-180deg);
}
.light-theme h2{
    vertical-align: middle;
    /* -ms-writing-mode: tb-lr; */
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(-180deg);
}

h4{
    font-weight: 600;
}


.dark-theme p,
.dark-theme ul li a{
    display: block;
    color: rgba(255,255,255,0.5);
    padding: 10px;
}
.dark-theme ul li a:hover{
    color: #fff;
    transition: .2s;
}

.light-theme p,
.light-theme ul li span{
    display: block;
    color: rgba(0,0,0,0.5);
}
.light-theme ul li a:hover{
    color: #fff;
    transition: .2s;
}

.light-theme .progress-indicator-container{
    background-color: #eee;
}

</style>
