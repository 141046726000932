<template>
    <div class="container-fluid text-center"
         :key="currentLanguage">
	
      <Header @setCurrentLanguage="setCurrentLanguage($event)"
              @setCurrentTheme="setCurrentTheme($event)"></Header>
      <Intro></Intro>
      <MyNumbers></MyNumbers>
      <Skills></Skills>
      <Projects></Projects>
      <Footer></Footer>
	
    </div>
</template>

<script>

import $ from 'jquery';
import Header from '@/components/Header.vue';
import Intro from '@/components/Intro.vue';
import MyNumbers from '@/components/MyNumbers.vue';
import Projects from '@/components/Projects.vue';
import Skills from '@/components/Skills.vue';
import Footer from '@/components/Footer.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { useStore } from "vuex";
import ptBr from '@/locale/pt-br.json'
import en from '@/locale/en.json'
import { computed, reactive, toRefs, onBeforeMount, onMounted } from "vue";

const analytics = Analytics({
  app: 'davidbertoncello.dev',
  plugins: [
    googleAnalytics({
      trackingId: 'G-HJ87B7DZZN'
    })
  ]
});
analytics.page();

export default {
  name: 'Home',
  components: {
    Header, Intro, MyNumbers, Skills, Projects, Footer
  },

  setup() {
    const store = useStore();

    const data = reactive({
      languages: {
        "pt-br" : ptBr,
        "en" : en
      },
      currentTheme: computed(() =>
        store.getters.currentTheme
      ),
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )				
    })

    onBeforeMount(() => {
      $('title').html('David Bertoncello');
      setCurrentLanguage('pt-br');
      chooseThemeFromTime();
    })

    function setCurrentLanguage(language){
      console.log(language)
      document.lang = language;
      //   $('html').attr('lang', language)
      console.dir(data.languages[language])
      store.commit('setCurrentLanguage', data.languages[language] );
    }


    function chooseThemeFromTime(){
				
      setCurrentTheme('dark-theme')

      // const date = new Date();
      // var hours = parseInt(date.getHours());

      // if(hours >= 8 && hours <= 18){
      // 	this.setCurrentTheme('light-theme')
      // }
      // else{
      // 	this.setCurrentTheme('dark-theme')
      // }
				

    }
			
    function setCurrentTheme(theme){
      console.log(theme)
      store.commit('setCurrentTheme', theme);
    //   $('body').attr('class', this.currentTheme);
    }

    return {
      ...toRefs(data),
      setCurrentTheme,
      setCurrentLanguage
    }


  }
}

</script>

<style scoped>
.container-fluid {
	padding-left: 0px;
	padding-right: 0px;
}

.link:hover{
	cursor: pointer;
}

.text-justify {
	text-align: justify;
}

.alert-ambiente {
	text-align: justify;
	font-size: 0.9rem;
	max-width: 500px !important;
	margin: 10px auto;
}

@media (max-width: 768px) {
	.img-10 {
		width: 30%;
	}

	.alert-ambiente {
		width: 90%;
	}
}

</style>