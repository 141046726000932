<template>
    <div class="container-fluid pt-4 ps-5 pe-5 pb-4">

      <div class="row">
        <div class="col-6">
          <div class="d-inline-block">
            <b class="font-size-3 me-4">
              8
            </b>
          </div>
          <div class="d-inline-block text-start">
            {{ currentLanguage.numbers.experience[0] }}<br/> {{ currentLanguage.numbers.experience[1] }}
          </div>
        </div>

        <div class="col-6">
          <div class="d-inline-block">
            <b class="font-size-3 me-4">
              62
            </b>
          </div>
          <div class="d-inline-block text-start">
            {{ currentLanguage.numbers.projects[0] }}<br/> {{ currentLanguage.numbers.projects[1] }}
          </div>
        </div>
      </div>

    </div>
</template>

<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/theme/core.css'
import { isMobile } from '@/utils/responsive'

// import 'animate.css';
// import {animate} from '@/utils/animate.js';

export default {

  name: 'MyNumbers',

  data: function () {
    return {

      currentLanguage: this.$store.getters.currentLanguage,
      isMobile
    }
  },

  mounted () {
    // animate();
  },

  methods: {

  }

}

</script>

<style scoped>

h4{
    font-weight: 600;
}
.light-theme .container-fluid{
  background-color: #eee;
  color: #666;
  transition: .2s;

}



.dark-theme .container-fluid{
  background-color: #090909;
  color: #fff;
  transition: .2s;
}

</style>
