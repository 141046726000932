<template>
    <footer class="container-fluid pt-2 ps-5 pe-5 pb-2">

      <div class="row text-white p-0 mt-4">
        <p class="m-0">
          {{ currentLanguage.footer.madeWith }} <i class="fa fa-heart"></i> {{ currentLanguage.footer.and }} <i class="fas fa-coffee"></i>.
        </p>
      </div>
      <div class="row text-white">
        <p>Copyright &copy; 2022. {{ currentLanguage.footer.allRightsReserved }}.</p>
      </div>

    </footer>
</template>

<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import '@/theme/core.css'

import { isMobile } from '@/utils/responsive'

// import 'animate.css';
// import {animate} from '@/utils/animate.js';

export default {

  name: 'Footer',

  data: function () {
    return {

      currentLanguage: this.$store.getters.currentLanguage,
      isMobile
    }
  },

  mounted () {

  },

  methods: {

  }

}

</script>

<style scoped>


.light-theme .container-fluid{
    background-color: #eee;
}
.light-theme p{
  color: #666;
}

    

.container-fluid{
    background-color: #090909;
}

h4{
    font-weight: 600;
}

p {
    display: block;
    color: rgba(255,255,255,0.5);
    padding: 10px;
}

</style>
