<template>
    <div class="container-fluid p-5">

      <div class="d-inline">
        <div class="d-inline-block text-start align-top col-6 ps-5">
          <h1>{{ currentLanguage.intro.title }} &#128075; </h1>
          <p class="text-grey">
            {{ currentLanguage.intro.phrase }}
          </p>
          
          <a class="btn btn-primary rounded-5 pt-2 pb-2 ps-3 pe-3"
             href="https://calendly.com/david-bertoncello"
             target="_blank">
            <i class="fa fa-mug-hot"></i> {{ currentLanguage.intro.buttonText }}
          </a>
        </div>

        <div class="d-inline-block col-6">
          <figure class="border-2 border-black">
            <img src="/assets/images/profile.jpg"
                 width="200"
                 class="rounded-circle p-1 border border-2 border-primary" />
            <span class="bullet"></span>
          </figure>
        </div>
      </div>

    </div>
</template>

<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import '@/theme/core.css'

import { isMobile } from '@/utils/responsive'

// import 'animate.css';
// import {animate} from '@/utils/animate.js';

export default {

  name: 'Intro',

  data: function () {
    return {

      currentLanguage: this.$store.getters.currentLanguage,
      isMobile
    }
  },

  mounted () {

  },

  methods: {

  }

}

</script>

<style scoped>

p {
    font-family: 'Poppins', sans-serif;
}

.dark-theme p{

}

.light-theme p{
    color: #333;
}

@media only screen and (max-width: 600px){

}

</style>
