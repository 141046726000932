<template>
    <div class="container-fluid p-0 pb-4">

      <div class="row">
        <div class="col-6 text-end">
          <div class="row">
            <div class="col-11">
              {{ before }}
            </div>
            <div class="col-1">
              <i class="fa fa-chevron-left text-danger ms-2"></i>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-1">
              <i class="fa fa-chevron-right text-success me-2"></i>
            </div>
            <div class="col-11">
              {{ after }}
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>

import '@fortawesome/fontawesome-free/css/all.css'

export default {
  name: 'BeforeAndAfter',
  data: function () {
    return {

    }
  },

  props: {
    before: String,
    after: String
  },

  methods: {

  }
}

</script>

<style scoped>

.light-theme .row{
  color: #666;
}

</style>
