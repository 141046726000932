<template>
    <router-view :class="currentTheme"></router-view>
</template>

<script>

import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/theme/core.css'

export default {
  name: 'App',

  setup(){
    const store = useStore();

    const data = reactive({
      currentTheme: computed(() =>
        store.getters.currentTheme
      )
    })

    return {
      ...toRefs(data)
    };

  }

}
</script>
