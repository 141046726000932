<template>
    <div class="container-fluid text-white ps-5 pe-5">

      <div class="d-block text-start ps-5 pe-5">
        <h2 class="d-inline-block font-size-3 fw-bold m-auto p-0">
          {{ currentLanguage.projects.bld.title1 }}: <b class="text-orange">
            {{ currentLanguage.projects.bld.title2 }}
          </b>
        </h2>
        <p class="mt-4">
          &emsp; {{ currentLanguage.projects.bld.description }}
        </p>
      </div>

      <div class="d-block text-start ps-5 pe-5">

        <div class="row pt-3 pb-4">
          <div class="col-6 text-end">
            <div class="col-11">
              <span class="badge bg-danger">
                {{ currentLanguage.projects.bld.beforeText }}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div class="col-11 float-end">
              <span class="badge bg-success">
                {{ currentLanguage.projects.bld.afterText }}
              </span>
            </div>
          </div>
        </div>

        <BeforeAndAfter v-for="(item, index) of currentLanguage.projects.bld.beforeAndAfterList" 
                        :key="'before-and-after-' + index"
                        :before="item.before" 
                        :after="item.after"></BeforeAndAfter>

      </div>

      <div class="row">
        <div class="col-5 m-auto align-middle text-end p-5">
          <figure><img src="/assets/images/notebook+smartphone.png" /></figure>
        </div>
        <div class="col-7 text-start p-5">

          <ul class="list-unstyled mt-5">
            <li>
              <b class="font-size-1-5 text-orange">
                {{ currentLanguage.projects.bld.topic1 }}
              </b> 
              {{ currentLanguage.projects.bld.description1 }}
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                {{ currentLanguage.projects.bld.topic2 }}
              </b> 
              {{ currentLanguage.projects.bld.description2 }}
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                {{ currentLanguage.projects.bld.topic3 }}
              </b> 
              {{ currentLanguage.projects.bld.description3 }}
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                {{ currentLanguage.projects.bld.topic4 }}
              </b> 
              {{ currentLanguage.projects.bld.description4 }}
            </li>
          </ul>
        </div>

      </div>

    </div>
</template>

<script>

import '@fortawesome/fontawesome-free/css/all.css'
import BeforeAndAfter from '@/components/BeforeAndAfter.vue'

export default {
  name: 'Projects',
  data: function () {
    return {
      currentLanguage: this.$store.getters.currentLanguage
    }
  },

  methods: {

  },

  components: {
    BeforeAndAfter
  }
}

</script>

<style scoped>

.light-theme ul li{
  color: #111;
}
.light-theme ul li:after{
background-color: transparent !important;
}

.light-theme h2{
color: #666;
}
.light-theme p{
color: #666;
}

.container-fluid{
    padding-bottom: 200px;
}

figure img {
    max-width: 90%;
}

ul li {
    position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    padding-bottom: 2.5em;
}

ul li:after {
    /* bullets */
    content: ' ';
    position: absolute;
    left: -22px;
    top: 10px;
    display: block;
    width: 14px;
    height: 14px;
    border: 3px solid #f26321 !important;
    background-color: #111 !important;
    border-radius: 50%;
}

ul li:before {
    /* lines */
    content:"";
    position: absolute;
    left: -16px; /* adjust manually */
    border-left: 2px solid rgba(255, 255, 255, 0.1);
    height: 100%;
    width: 1px;
}

ul li:first-child:before {
   /* first li's line */
   top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul li:last-child:before {
    /* last li's line */
   height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

</style>
