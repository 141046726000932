import $ from 'jquery'
import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
// const router = new Router({
// 	mode: 'history',
// 	base: process.env.BASE_URL,
// 	routes
// });

router.beforeEach((to, from, next) => {
  // Remove backdrop quando window.history.back(), botão de voltar no celular
  try {
    $('.modal-backdrop').remove()
    $('.modal-backdrop').remove()
  } catch (e) {
    // continue
  }
  next()
})

export default router
