<template> 
    <header class="p-5 text-end">

      <div class="float-start">
        <a class="me-5"
           @click="setCurrentTheme( (currentTheme === 'dark-theme' ? 'light-theme' : 'dark-theme') )"
           :title="`Alternar para modo ` + (currentTheme === 'dark-theme' ? 'claro' : 'escuro')">
          <i class="fas fa-moon"></i>
        </a>
        <!-- <a class="me-5"
           @click="share()">
          <i class="fas fa-share-alt"></i>
        </a> -->

        <div class="dropdown d-inline-block">
          <a class="me-5"
             data-bs-toggle="dropdown">
            <i class="fas fa-globe-americas"></i>
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item"
                   @click="setCurrentLanguage('pt-br')">
              <small>Português</small>
            </a></li>
            <li><a class="dropdown-item"
                   @click="setCurrentLanguage('en')">
              <small>English</small>
            </a></li>
          </ul>
        </div>

      </div>

      <a href="https://github.com/davidbertoncello"
         target="_blank"
         class="me-5">
        <i class="fab fa-github"></i> Github
      </a>
      <a href="https://linkedin.com/in/davidbertoncello"
         target="_blank">
        <i class="fab fa-linkedin-in"></i> LinkedIn
      </a>

    </header>
</template>

<script>

import { isMobile } from '@/utils/responsive'
import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Header',
  setup (_, { emit }) {
    const store = useStore()
    const data = reactive({
      currentTheme: computed(() =>
        store.getters.currentTheme
      ),
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      ),
      isMobile
    })

    function setCurrentTheme (theme) {
      emit('setCurrentTheme', theme)
    }
    function setCurrentLanguage (language) {
      emit('setCurrentLanguage', language)
    }

    function share () {
      Navigator.share({
        title: 'David Bertoncello',
        text: 'this.currentLanguage.header.share',
        url: 'https://davidbertoncello.dev'
      })
    }

    return {
      ...toRefs(data),
      setCurrentTheme,
      setCurrentLanguage,
      share
    }
  }
}

</script>

<style scoped>

.light-theme header a{
	color: #555;
	font-size: 1.2rem;
	transition: .2s;
}
.light-theme header a:hover{
	color: #999;
	transition: .2s;
	cursor: pointer;
}

.dark-theme header a{
	color: #cfcfcf;
	font-size: 1.2rem;
	transition: .2s;
}
.dark-theme header a:hover{
	color: #fff;
	transition: .2s;
	cursor: pointer;
}

.dropdown-item{
	font-size: 1rem;
	color: #333;
}
.dropdown-item:hover{
	color: #333;
	cursor: pointer;
}
.dropdown-item:active{
	background-color: inherit;
}

.dropdown-menu li a {
  color: #333
}
.dropdown-menu li a:hover {
  color: #333
}

@media only screen and (max-width: 600px){

}

</style>
