<template>
    <div class="progress-indicator-container">
      <div class="progress-indicator"
           :style="'width:' + this.percent + '%; float:' + this.direction "></div>
    </div>
</template>

<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import '@/theme/core.css'

import { isMobile } from '@/utils/responsive'

// import 'animate.css';
// import {animate} from '@/utils/animate.js';

export default {

  name: 'ProgressIndicator',

  data: function () {
    return {
      isMobile

    }
  },

  mounted () {

  },

  props: {
    percent: Number,
    direction: String
  }

}

</script>

<style scoped>

@import '../theme/variables.css';

.progress-indicator-container{
    height: 20px;
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
	transition: width .3s;
}
.progress-indicator{
    display: block;
    height: 20px;
    background-color: var(--primary);
    border-radius: 5px;
}

@media only screen and (max-width: 600px){

}

</style>
